::-webkit-scrollbar {
  width: 5px;
  background-color: #fff;
}
::-webkit-scrollbar:horizontal {
  height: 5px;
  background-color: #fff;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ccc;
}

.base-style {
  background-color: #fff;
  margin-bottom: 1rem;
  padding: 1.5rem;
  border-radius: 5px;
  box-shadow: 0px 2px 13px 0px rgba(228, 228, 228, 0.6);
}

.b-top-dark {
  border-top: 1px solid #dadada;
}
.b-right-dark {
  //border-right: 1px solid #dadada;
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
}
.b-bottom-dark {
  border-bottom: 1px solid #dadada;
}

.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 0px !important;
}
span.anticon.anticon-pause-circle {
  font-size: 15px;
}
.ant-tag > .MuiSvgIcon-root {
  font-size: 14px;
  margin-right: 4px;
  margin-bottom: -1px;
  padding-top: 2px;
}
.fullscreen {
  overflow-y: scroll;
  background-color: #fff;
}
.fullscreen-icon {
  top: 18px;
  right: 18px;
  position: absolute !important;
}
.b-shadow {
  box-shadow: 2px 8px 8px 0 rgba(29, 35, 41, 0.05);
}

.header-icon {
  height: 25px;
}
.ant-back-top {
  left: 285px;
  right: unset;
}
.ant-back-top-content {
  border-radius: 0px !important;
}
.ant-table-title {
  color: #ff8800;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  background: #fff7e6;
  text-transform: capitalize;
}
.user {
  padding-right: 6px;
  padding-top: 0px;
  padding-bottom: 14px;
  padding-left: 6px;
  margin-right: 3px;
  border: 1px solid;
}
.user-status {
  position: absolute !important;
  right: 72px;
  top: 9px;
}
.user-status .ant-badge-status-text {
  font-size: 10px;
}
.sb-avatar__text {
  border-radius: 2px;
}
.light-green-grey {
  .ant-collapse-content,
  .ant-collapse-header {
    background-color: #f0f2f5 !important;
  }
}
.ant-calendar-picker {
  width: 350px !important;
}
.card-padding {
  .ant-card-body {
    padding: 10px;
  }
}
.white_table {
  .ant-descriptions-view {
    border: none !important;
  }
  table {
    tr {
      border: none !important;
      th {
        padding: 5px 9px !important;
        background-color: #fff !important;
        border-right: 1px solid #fff !important;
      }
      td {
        padding: 5px 9px !important;
        background-color: #fff !important;
        border-right: 1px solid #fff !important;
      }
      td:before {
        content: ":";
      }
    }
  }
}
.text-bold {
  font-weight: 600;
}
.periods-before::before {
  content: ":";
}
.divider-text {
  span {
    font-size: 1.3em;
    font-weight: 400;
  }
}

.nested-child thead {
  display: none;
  tr td {
    background-color: aliceblue;
  }
}
.nested-child > div > div > div > div > div > table > tbody tr td {
  background-color: aliceblue !important;
}
.svg-primary svg {
  fill: #ff8800 !important;
}

.site-badge-count-4 .ant-badge-count {
  background-color: #fff;
  color: #999;
  box-shadow: 0 0 0 1px #d9d9d9 inset;
}
.showstatus > span {
  top: 48px;
}
@media (max-width: 991px) {
  .ant-popover-placement-bottomRight {
    width: 60% !important;
  }
}
@media (min-width: 992px) {
  .ant-popover-placement-bottomRight {
    width: 20% !important;
  }
}

.unread h4 {
  font-weight: bold;
}

.sticky-column {
  width: 5%;
  position: sticky;
  right: 0px;
}
.tooltip-mw {
  max-width: 160px !important;
}
.ant-descriptions-title {
  color: #fff !important;
  padding-bottom: 7px;
  text-align: center;
  border-bottom: 1px solid;
}
.ant-descriptions-item-label {
  font-size: 15px;
  color: #fff !important;
  font-weight: bold !important;
}
.ant-descriptions-item-content {
  font-size: 15px;
  color: #fff !important;
  font-weight: bold !important;
}
.ft-menu {
  font-size: 20px !important;
}
.ant-calendar-picker {
  width: auto !important;
}
.fs-11 {
  font-size: 11px !important;
}
.bg-vm-list {
  background-color: #d4eaf4;
}
.infinite-container {
  height: 75vh;
  overflow: auto;
}
.voice-message-box::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 133%;
  opacity: 0.3;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("../assets/images/voice_icon.png");
}
.voicemail-player {
  border: 1px solid #ccc;
}
.rhap_container {
  border: 1px solid #f0f0f0;
  box-shadow: none;
}
.agent-table .ant-collapse-content-box {
  padding: 0 !important;
}
.agent-table .ant-collapse-header {
  padding: 4px 8px !important;
  border-radius: 0px !important;
  color: #ff8800 !important;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  background: #fff7e6;
  text-transform: capitalize;
}
.agent-table .ant-collapse {
  border-radius: 0px !important;
}
.dynamic-delete-button {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.dynamic-form-margin {
  .ant-form-item {
    margin-bottom: 2px;
  }
}

@primary-color: rgb(255, 136, 0);@layout-header-background: #FFF;@menu-item-color: #004570;@font-size-base: 13px;@layout-header-color: @primary-color;@heading-color: rgba(0, 0, 0, 0.95);@text-color: rgba(0, 0, 0, 0.72);@text-color-secondary: rgba(0, 0, 0, 0.60);@font-family: spezia,sans-serif;